import {
  redirectToSubdomain,
  useOrganization
} from '@components/OrganizationBoundary';
import OrganizationsDropdownMenuItem from '@components/Organizations/OrganizationsDropdownMenu/OrganizationsDropdownMenuItem';
import { useTypedSelector } from '@hooks';
import {
  organizationIdsSelector,
  organizationItemStateSelector,
  ownOrganizationItemStateSelector
} from '@redux/selectors/organizations';
import store from '@redux/store';
import { Button, Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { memo, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type OrganizationsDropdownMenuProps = {
  children: React.ReactNode;
};

export default memo(function OrganizationsDropdownMenu({
  children
}: OrganizationsDropdownMenuProps) {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const list = useTypedSelector((state) => organizationIdsSelector(state));
  const ownOrganization = useTypedSelector((state) =>
    ownOrganizationItemStateSelector(state)
  );
  const { currentOrganization } = useOrganization(true);
  const menuItems: ItemType[] = useMemo(() => {
    const items: ItemType[] = list.map((id) => ({
      key: id,
      label: (
        <OrganizationsDropdownMenuItem
          organizationId={id}
          setIsMenuVisible={setIsMenuVisible}
        />
      ),
      onClick: () => {
        const organization = organizationItemStateSelector(store.getState(), {
          organizationId: id
        });
        if (!organization.entity) return;
        if (organization.entity.id === currentOrganization.entity?.id) return;
        redirectToSubdomain(organization.entity.subdomain);
      }
    }));
    if (!ownOrganization)
      items.push({
        key: 'create_own_workspace',
        label: (
          <Button
            onClick={() => navigate('/onboarding', { replace: true })}
            type="primary"
          >
            Create own workspace
          </Button>
        )
      });
    return items;
  }, [navigate, list, ownOrganization, currentOrganization.entity?.id]);

  if (list.length <= 1 && ownOrganization) return null;

  return (
    <Dropdown
      className="select_organization"
      overlayClassName="select_organization_dropdown"
      open={isMenuVisible}
      onOpenChange={(v) => setIsMenuVisible(v)}
      overlay={
        <Menu
          selectedKeys={[currentOrganization.entity?.subdomain || '']}
          items={menuItems}
        />
      }
      trigger={['click']}
      placement="bottomRight"
    >
      {children}
    </Dropdown>
  );
});
